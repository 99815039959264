<template>
  <div>
    <FormulateForm
      #default="{ hasErrors, isLoading }"
      v-model="values"
      class="max-w-2xl mb-12 mx-auto"
      @submit="submit"
    >
      <h2
        class="tg-mobile-header-3 md:tg-desktop-header-3 mb-7.5"
        v-text="$t('need_help')"
      />
      <FormulateInput
        type="text"
        name="question"
        :label="$t('what_is_subject_of_message')"
        :show-required-label="true"
        validation="required:trim"
      />
      <FormulateInput type="textarea" name="message" :label="$t('message')" />
      <div class="my-7.5">
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="hasErrors || isLoading"
          :input-class="['w-full lg:w-auto']"
          outer-class="my-0"
        >
          <i
            :class="[
              'far mr-2',
              isLoading ? 'fa-spinner-third animate-spin' : 'fa-paper-plane'
            ]"
          />
          {{ $t('send_message') }}
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      values: {}
    };
  },
  methods: {
    async submit(data) {
      await data;
    }
  }
};
</script>
